<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>疾病管理</el-breadcrumb-item>
      <el-breadcrumb-item>热门疾病</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <el-button @click="edit({index:3})">编辑</el-button> -->
    <el-table :data="list" border style="width: 100%" height="1000">
      <el-table-column prop="index" label="首页" :formatter="filterindex"></el-table-column>
      <el-table-column prop="id" v-if="false"></el-table-column>
      <el-table-column prop="name" label="科室名"></el-table-column>
      <el-table-column width="200">
        <template slot-scope="scope">
          <div class="opt">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="edit(scope.row)"
              >编辑</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 修改绑定疾病 -->
    <el-dialog title="修改科室" :visible.sync="changeIllId">
      <el-select
        v-model="choiceill"
        placeholder="请选择科室"
        style="width:150px;margin-right: 20px;"
        clearable
        filterable
        @clear="clear()"
        size="small"
      >
        <el-option v-for="item in illlist" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <!-- <el-input
        placeholder="请输入疾病名"
        clearable
        prefix-icon="el-icon-search"
        v-model="search"
        style="width: 200px"
        @clear="searchclear"
        size="small"
      >
      </el-input>
      <el-button
        type="primary"
        style="margin-left: 10px"
        size="small"
        @click="query"
        >查询</el-button
      >
      <div style="margin: 10px 0">
        <el-radio-group v-model="choiceill">
          <el-radio
            v-for="item in illlist"
            :key="item.id"
            :label="item.id"
            style="
              width: 150px;
              margin-bottom: 10px;
              text-align: left;
              float: left;
            "
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
      </div> -->
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="changeIllId = false">取 消</el-button>
        <el-button type="primary" @click="changeill">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      changeIllId: false,
      changemsg: "",
      illlist: [],
      search: "",
      choiceill: "",
      indexoptions: [
        { id: 1, name: "最关注的病名" },
        { id: 2, name: "节目播出的病" },
        { id: 3, name: "有效率最高的病" },
      ],
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    filterindex(row) {
      var str = "";
      this.indexoptions.forEach((element) => {
        if (row.index == element.id) {
          str = element.name;
        }
      });
      return str;
    },
    getdata() {
      this.axios.get("/gu/get_gu_department_by_index").then((res) => {
        if (res.data.code == 0) {
          var arr = res.data.result;
          for (var m = 0; m < arr.length; m++) {
            arr[m].index = m + 1;
          }
          this.list = res.data.result;
        } else {
          this.list = [];
        }
      });
    },
    getilldata() {
      this.axios
        .get("/gu/get_gu_department")
        .then((rs) => {
          if (rs.data.code == 0) {
            this.illlist = rs.data.result;
          } else {
            console.log(rs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    edit(row) {
      this.changeIllId = true;
      this.changemsg = row;
      this.illlist = [];
      // this.search = "";
      this.choiceill = "";
        this.getilldata();
    },
    query() {
      this.axios
        .get("/gu/get_gu_ill_lib_like_name?ill_lib_name=" + this.search)
        .then((res) => {
          if (res.data.code == 0) {
            this.illlist = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchclear() {
      this.search = "";
      this.illlist = [];
      this.choiceill = '';
    },
    changeill() {
      if (this.choiceill == "") {
        this.$message({
          message: "请选择科室",
          type: "error",
        });
        return;
      }
      this.axios
        .post(
          "/gu/update_gu_department_index",
          this.qs.stringify({
            dp_id: this.choiceill,
            type: this.changemsg.index,
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.changeIllId = false;
            this.getdata();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>